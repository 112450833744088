<template>
  <div class="container mb-5">
    <div class="container-header mt-4 mb-4">
      <div class="row align-items-center px-3">
        <div class="col-md-6"><h2>Senarai Soal Jawab Ibadat</h2></div>
        <div class="col-md-6">
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-outline-secondary w-50 me-2"
              data-bs-toggle="modal"
              data-bs-target="#addPastQuestionModal"
              @click="addQuestionOpen"
            >
              Tambah Soalan +
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container bg-white">
      <div class="row mx-0">
        <div class="col-md-12">
          <div class="container-box mt-2">
            <table class="table table-hover table-borderless mt-3">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Soalan</th>
                  <th scope="col">Jawapan</th> 
                  <th scope="col">Tag</th>                  
                  <th scope="col">Tindakan</th>
                </tr>
              </thead>
              <tbody v-if="pastQuestions && pastQuestions.length">
                <tr v-for="(question, i) in pastQuestions" :key="question.id">
                  <td scope="row">{{ i + 1 }}</td>
                  <td>
                    {{ question.question }}
                  </td>
                  <td>
                    {{ question.answer }}
                  </td>                  
                  <td v-if="question.tags.data.length">
                    <span
                        v-for="(tag, i) in question.tags.data"
                        :key="tag.id + i"
                        class="badge bg-secondary me-1"
                      >
                        {{ tag.attributes.name }}
                      </span>
                  </td>
                  <td v-else>
                    -
                  </td>
                  <td style="text-align: center">
                    <span
                      class="mx-2"
                      @click="editQuestionOpen(question, i)"
                      data-bs-toggle="modal"
                      data-bs-target="#addPastQuestionModal"
                      ><i class="fas fa-pen"></i
                    ></span>
                    <span
                      class="mx-2"
                      @click="deleteQuestion(question, i, false)"
                      ><i class="fas fa-trash"></i
                    ></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
          

    <!-- Modal -->
    <div
      class="modal"
      id="addPastQuestionModal"
      tabindex="-1"
      aria-labelledby="addPastQuestionModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row mx-0">
              <div class="col-12 mt-2">
                <h5>{{ isEdit ? 'Ubah Soalan' : 'Tambah Soalan' }}</h5>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1">Soalan</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="pastQForm.question"
                    placeholder="Masukkan soalan"
                  />
                </div>
                <div class="form-group mt-3">
                  <label class="form-check-label mb-1">Jawapan</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="pastQForm.answer"
                    placeholder="Masukkan jawapan"
                  />
                </div>                
                <!-- <div class="form-group mt-3" v-if="pastQForm.newTags.length">
                  <label class="form-check-label mb-1">Tags</label><br/>
                  <label
                    v-for="(eTag, i) in pastQForm.ETags"
                    :key="'eTag' + i"
                    class="btn btn-outline-secondary"
                  >
                    {{ eTag.name }}<i class="fa fa-times-circle ms-2"></i>
                  </label>
                  <label
                    v-for="(nTag, i) in pastQForm.newTags"
                    :key="'nTag' + i"
                    class="btn btn-outline-secondary"
                  >
                    {{ nTag.name }}<i @click="pastQForm.newTags.splice(i, 1)" class="fa fa-times-circle ms-2"></i>
                  </label>
                </div> -->
                <div class="form-group mt-3">
                  <label class="form-check-label mb-1">Kategori</label>
                  <div class="input-group mb-3">
                    <div class="input-group-text">
                      <select class="form-select" id="tag-select" v-model="pastQForm.tags[0]">
                        <option selected disabled>Pilih dari tag sedia ada</option>
                        <option
                          v-for="tag in originalTags"
                          :key="tag.id"
                          :value="tag.id"
                        >
                          {{ tag.name }}
                        </option>
                      </select>
                    </div>
                    <!-- <input id="tag-input" type="text" class="form-control" placeholder="Masukkan label dan tekan 'Enter'" @keypress="tagEnter($event)"> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-4 justify-content-center">
            <button
              type="button"
              class="btn btn-outline-secondary"
              @click="deleteQuestion(selectedQuestion, selectedQuestionP, true)"
            >
              <i class="fas fa-trash"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Batal
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="submitQuestion()"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../utils/API";
import $ from "jquery";

export default {
  data() {
    return {
      isEdit: false,
      pastQuestions: null,
      originalTags: null,
      tags: null,
      pastQForm: {
        question: null,
        answer: null,        
        tags: []
      },
      selectedQuestion: null,
      selectedQuestionP: null
    };
  },
  methods: {
    goto(_path) {
      this.$router.push(_path);
    },
    async fetchData() {
      const res = await API.get("past-questions?populate=*&pagination[start]=0&pagination[limit]=1000&sort[0]=id");
      this.pastQuestions = res.data.data
        ? res.data.data.map((o) => {
            return Object.assign({}, { id: o.id }, o.attributes);
          })
        : [];
    },
    tagSelect(e) {
      let selectedIndex = this.tags.findIndex(o => o.id == e.target.value)
      this.pastQForm.newTags.push(Object.assign(this.tags[selectedIndex], {new: true}))
      this.tags.splice(selectedIndex, 1)
      $('#tag-select').val(null);
      $('#tag-select').prop('selectedIndex', 0);
    },
    async tagEnter(e) {
      if (e.charCode === 13) {
        const res = await API.post('tags', { data: {name: e.target.value }});
        this.pastQForm.newTags.push(Object.assign(res.data.data.attributes, {id: res.data.data.id, new: true}))
        this.originalTags.push(Object.assign(res.data.data.attributes, {id: res.data.data.id}))
        $('#tag-input').val(null);
      }
    },
    async deleteQuestion(q, i, modal) {
      const res = await API.delete(`past-questions/${q ? q.id : this.selectedQuestion.id}`)
      if (res.toDelete)
        this.pastQuestions.splice(i ? i : this.selectedQuestionP, 1)
      if (modal) {
        $("#addPastQuestionModal").toggle();
        $(".modal-backdrop").remove();
      }
    },
    async submitQuestion() {
      console.log(this.pastQForm)
      // if (this.pastQForm.newTags.length)
      //   this.pastQForm.tags = this.pastQForm.newTags.map((p) => {return p.id })
      if (!this.isEdit) {
        const res = await API.post(`past-questions?populate=*`, {data: this.pastQForm})
        this.pastQuestions.push(Object.assign({}, res.data.data.attributes, {id: res.data.data.id}))
      } else {
        const res = await API.put(`past-questions/${this.selectedQuestion.id}?populate=*`, {data: this.pastQForm});
        this.pastQuestions.forEach((q) => {
          if (q.id == this.selectedQuestion.id)
            q = Object.assign(q, Object.assign(res.data.data.attributes, {id: res.data.data.id}));
        });
      }
      $("#addPastQuestionModal").toggle();
      $(".modal-backdrop").remove();
    },
    addQuestionOpen() {
      this.isEdit = false;
      this.tags = [...this.originalTags];
      this.pastQForm = {
        question: null,
        answer: null, 
        tags: [],
        section: 'Maklumat Umum'
      }
    },
    editQuestionOpen(q, i) {
      const currentTags = q.tags.data.length ? q.tags.data.map((o) => { return Object.assign({id: o.id})}) : [];
      // this.tags = currentTags.length ? [...this.originalTags.filter((o) => {
      //   return currentTags.some((o2) => {
      //     return o2.id != o.id;
      //   });
      // })] : [...this.originalTags];
      this.isEdit = true;
      this.selectedQuestion = q;
      this.selectedQuestionP = i;
      this.pastQForm = {
        question: q.question,
        answer: q.answer,        
        tags: currentTags.length > 0 ? currentTags[0] : ['']
      }
    }
  },
  async mounted() {
    await this.fetchData();
    const res = await API.get(`tags`);
    this.originalTags = res.data.data.map((o) => {
      return Object.assign({}, { id: o.id }, o.attributes);
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";
</style>